import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store/index'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
    store,
    vuetify,

    render(h) {
        return h(App)
    },
}).$mount('#app')

